import { useEffect, useState, useRef } from "react";
import { Spin } from 'antd';
import { Services } from "../../__services";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { CONSTANT, getFromLocalStorage } from "../../Utils";
import { notification } from 'antd';
import { UserVisitSummary } from "./User";
import { CoordinatorVisitSummary } from "./Coordinator";


export const VisitSummary = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [adultinitial, setAdultinitial] = useState();
    const [visitInProgress, setVisitInProgress] = useState();
    const [visitInProgressFirst, setVisitInProgressFirst] = useState();
    const [visitComplete, setVisitComplete] = useState();
    const [visitMicrobiology, setVisitMicrobiology] = useState();
    const [visitMicrobiologyResult, setVisitMicrobiologyResult] = useState();
    const [visitMicrobiologyComplete, setVisitMicrobiologyComplete] = useState();
    const [showInfectionFragmentRight, setShowInfectionFragmentRight] = useState(false);
    const [showInfectionFragmentLeft, setShowInfectionFragmentLeft] = useState(false);
    const [showMedicationFragmentRight, setShowMedicationFragmentRight] = useState(false);
    const [showMedicationFragmentLeft, setShowMedicationFragmentLeft] = useState(false);
    const [showFirstVisitInfection, setShowFirstVisitInfection] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [submitCoordinator, setSubmitCoordinator] = useState();
    const [coordinatorVisit, setCoordinatorVisit] = useState();
    const isFirstRender = useRef(true);
    const { id } = useParams();

    useEffect(() => {
        if (isFirstRender.current) {
            getAllEncounters();
            isFirstRender.current = false
            return;
        }

        if (adultinitial && !visitInProgress) {
            postVisitNote();
        }

        if (coordinatorVisit) {
            postVisitCoordinator();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adultinitial, visitInProgress, coordinatorVisit])

    useEffect(() => {
        setTimeout(() => setSubmitCoordinator(false), 3000);
    }, [submitCoordinator])

    const onClickHandler = (type, value) => {
        if (type === 'visitComplete-diagnosis') {
            setTimeout(() => {
                postVisitComplete();
            }, 1000);
        } else if (type === 'visitNote') {
            setSubmitCoordinator(type);
        } else if (type === 'visitCompleteCoordinator') {
            setSubmitCoordinator(type);
            if (value) {
                setTimeout(() => {
                    postVisitComplete('coordinator');
                }, 1000);
            }
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'showInfectionRight') {
            setShowInfectionFragmentRight(value);
        } else if (type === 'showInfectionLeft') {
            setShowInfectionFragmentLeft(value);
        } else if (type === 'showMedicationHistoryRight') {
            setShowMedicationFragmentRight(value);
        } else if (type === 'showMedicationHistoryLeft') {
            setShowMedicationFragmentLeft(value);
        } else if (type === 'showFirstVisitInfection') {
            setShowFirstVisitInfection(value);
        } else if (type === 'setCoordinatorVisit') {
            setCoordinatorVisit(value);
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getFirstVisit = (response) => {
        return new Promise((resolve, reject) => {
            try {
                if (response.rows[0].visit.visit_type_id === CONSTANT.VISITCOORDINATOR) {
                    let params = `patient_id=${response.rows[0].patient.id}&encounter_type_id=${CONSTANT.ENCOUNTERVISITINPROGRESS}&customQuery=obs(obs_string),visit,creator`
                    Services.GetEncounterWithParams(params)
                        .then(response => {
                            if (response.status !== 400 && response.status !== 401 && response.rows.length) {
                                let firstVisit = response.rows.find(encounter => encounter.visit.visit_type_id === CONSTANT.VISITFIRST);
                                if (firstVisit) {
                                    setVisitInProgressFirst(firstVisit);
                                    resolve();
                                } else {
                                    reject();
                                }
                            } else {
                                reject();
                            }
                        })
                } else {
                    resolve();
                }
            } catch (err) {
                console.log('Find First diagnosis :', err);
                reject();
            }
        })
    }

    const getAllEncounters = () => {
        try {
            let params = `visit_id=${id}&customQuery=patient(person),obs,creator,visit`;
            Services.GetEncounterWithParams(params)
                .then(async response => {
                    if (response.status !== 400 && response.status !== 401 && response.rows.length) {
                        try {
                            // await getFirstVisit(response);
                        } catch (err) {
                            console.log('No first Visit Exception :', err);
                        }
                        setAdultinitial(response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERADULTINITIAL));
                        let visitINProgress = response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERVISITINPROGRESS);
                        try {
                            if (visitINProgress) {
                                let diagnosisObsRight = visitINProgress.obs.filter(visit => [CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY, CONSTANT.DIAGNOSISRIGHTEYECORNEAL].includes(visit.concept_id));
                                if (diagnosisObsRight.length) {
                                    diagnosisObsRight.forEach(obs => {
                                        if (obs.obs_string.value.match('Active corneal infection')) {
                                            setShowInfectionFragmentRight(true);
                                            setShowMedicationFragmentRight(true);
                                        }
                                    })
                                }
                                let diagnosisObsLeft = visitINProgress.obs.filter(visit => [CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY, CONSTANT.DIAGNOSISLEFTEYECORNEAL].includes(visit.concept_id));
                                if (diagnosisObsLeft.length) {
                                    diagnosisObsLeft.forEach(obs => {
                                        if (obs.obs_string.value.match('Active corneal infection')) {
                                            setShowInfectionFragmentLeft(true);
                                            setShowMedicationFragmentLeft(true);
                                        }
                                    })
                                }
                                let diagnosisCornealObs = visitINProgress.obs.filter(visit => [CONSTANT.DIAGNOSISCOREALINFECTION].includes(visit.concept_id));
                                if (diagnosisCornealObs.length) {
                                    diagnosisCornealObs.forEach(obs => {
                                        if (obs.obs_string.value.match('Yes, and this is patient’s FIRST VISIT')) {
                                            setShowFirstVisitInfection(true);
                                        }
                                    })
                                }
                            }
                        } catch (err) {
                            console.log('Find Diagnosis :', err);
                        }
                        setVisitInProgress(visitINProgress);
                        setVisitComplete(response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERVISITCOMPLETE));
                        setVisitMicrobiology(response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGY));
                        let visitMicrobiologyResult = response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYRESULTS);
                        try {
                            if (!visitMicrobiologyResult && location.state.user_type !== 'user') {
                                let user = await getFromLocalStorage('user');
                                let payload = {
                                    encounter_type_id: CONSTANT.ENCOUNTERMICROBIOLOGYRESULTS,
                                    creator_id: user.user.person_id,
                                    patient_id: response.rows[0]?.patient_id,
                                    visit_id: id,
                                }
                                Services.PostEncounter(payload).then(response => {
                                    if (response.status !== 400) {
                                        setVisitMicrobiologyResult({ ...response, obs: [] })
                                    }
                                })
                            } else {
                                setVisitMicrobiologyResult(visitMicrobiologyResult)
                            }
                        } catch (err) {
                            console.log('Creating encounter error :', err);
                        }
                        setVisitMicrobiologyComplete(response.rows.find(encounter => encounter.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYCOMPLETE));
                        setLoading(false);
                    } else {
                        openNotificationWithIcon('error', 'Server Issue', '');
                        setLoading(false);
                    }
                })
        } catch (err) {
            console.log('Get Encounter exception :', err)
        }
    }

    const postVisitNote = async () => {
        try {
            let user = await getFromLocalStorage('user');
            let payload = {
                encounter_type_id: CONSTANT.ENCOUNTERVISITINPROGRESS,
                creator_id: user.user.person_id,
                patient_id: adultinitial.patient_id,
                visit_id: id,
            }
            Services.PostEncounter(payload).then(response => {
                if (response.status !== 400 && response.status !== 401) {
                    setVisitInProgress(response)
                    openNotificationWithIcon('success', 'Visit Note Created', '');
                    setLoading(false);
                }
            })
        } catch (err) {
            console.log('Post Visit Note Exception :', err);
        }

    }

    const postVisitCoordinator = async () => {
        let user = await getFromLocalStorage('user');
        if (location.state.user_type === 'user' && coordinatorVisit) {
            // let payload = {
            //     patient_id: adultinitial.patient_id,
            //     visit_type_id: CONSTANT.VISITCOORDINATOR,
            //     location_id: user.user.person.location_id,
            //     creator_id: user.user.person_id
            // }
            // Services.PostVisit(payload).then(response => {
            //     if (response.status !== 400 && response.status !== 401) {
            //         let payload = {
            //             encounter_type_id: CONSTANT.ENCOUNTERADULTINITIAL,
            //             creator_id: user.user.person_id,
            //             patient_id: adultinitial.patient_id,
            //             visit_id: response.id,
            //         }
            //         Services.PostEncounter(payload).then(response => {
            //             if (response.status !== 400 && response.status !== 401) {
            //             }
            //         })
            //     }
            // })
            let payload = {
                encounter_type_id: CONSTANT.ENCOUNTERMICROBIOLOGY,
                creator_id: user.user.person_id,
                patient_id: adultinitial.patient_id,
                visit_id: id,
            }
            Services.PostEncounter(payload).then(response => {
                if (response.status !== 400 && response.status !== 401) {
                }
            })
        }
    }

    const postVisitComplete = async (type = 'visit') => {
        try {
            let user = await getFromLocalStorage('user');
            if ((type === 'visit' & !visitComplete) || (type === 'coordinator' & !visitMicrobiologyComplete)) {
                let payload = {
                    encounter_type_id: type === 'coordinator' ? CONSTANT.ENCOUNTERMICROBIOLOGYCOMPLETE : CONSTANT.ENCOUNTERVISITCOMPLETE,
                    creator_id: user.user.person_id,
                    patient_id: adultinitial.patient_id,
                    visit_id: id,
                }
                Services.PostEncounter(payload).then(response => {
                    if (response.status !== 400 && response.status !== 401) {
                        setVisitComplete(response)
                        openNotificationWithIcon('success', 'Visit Complete', '');
                        setLoading(false);
                        postVisitCoordinator(user);
                        navigate('/home', { state: location.state });
                    }
                })
            } else if (coordinatorVisit) {
                postVisitCoordinator(user);
                navigate('/home', { state: location.state });
            } else {
                openNotificationWithIcon('info', 'Visit Already Complete', '');
                navigate('/home', { state: location.state });
            }
        } catch (err) {
            console.log('Post Visit Complete Exception :', err);
        }
    }

    return (
        <>
            {loading && !adultinitial ?
                <Spin tip="Loading" size="large">
                    <div className="content" />
                </Spin> :
                <>
                    {location.state.user_type === 'user' ?
                        <UserVisitSummary
                            adultinitial={adultinitial}
                            visitInProgress={visitInProgress}
                            onChangeHandler={onChangeHandler}
                            onClick={onClickHandler}
                            showInfectionFragmentRight={showInfectionFragmentRight}
                            showInfectionFragmentLeft={showInfectionFragmentLeft}
                            showMedicationFragmentRight={showMedicationFragmentRight}
                            showMedicationFragmentLeft={showMedicationFragmentLeft}
                            showFirstVisitInfection={showFirstVisitInfection} /> :
                        visitMicrobiologyResult ? <CoordinatorVisitSummary
                            visitInProgress={visitInProgress}
                            visitInProgressFirst={visitInProgressFirst}
                            visitMicrobiology={visitMicrobiology}
                            visitMicrobiologyResult={visitMicrobiologyResult}
                            visitMicrobiologyComplete={visitMicrobiologyComplete}
                            onClick={onClickHandler}
                            submitCoordinator={submitCoordinator} /> : null
                    }
                    {contextHolder}
                </>
            }
        </>
    )
}