import axios from 'axios';
// import { Decryption, Encryption, getToken, Server } from '../Utils';
import { getToken, Server } from '../Utils';

axios.defaults.baseURL = Server.ENV.baseURL;

const getHeader = async (formData) => {
    return {
        "Content-Type": formData ? 'application/x-www-form-urlencoded; charset=UTF-8' : "application/json",
        "Authorization": `Bearer ${await getToken()}` || null
    }
}

export const getResponse = async (url) => {
    return axios(url, {
        method: 'GET',
        headers: await getHeader()
    }).then(response => {
        if (response.data) {
            if (response.data.success) {
                // response.data.data = Decryption(response.data.data);
                if (response.data.data.datas) return response.data.data.datas;
                else return response.data.data
            }
        }
    }).catch(e => {
        if (e.response.status === 401) {
            window.location.href = '/';
        } else return e.response
    })
}

export const postResponse = async (url, payload) => {
    return axios(url, {
        method: 'POST',
        headers: await getHeader(),
        // data: Encryption(JSON.stringify(payload))
        data: JSON.stringify(payload)
    }).then(response => {
        if (response.data.success) {
            // response.data.data = Decryption(response.data.data);
            return response.data.data
        }
    }).catch(e => {
        if (e.response.status === 401) {
            window.location.href = '/'
        } else return e.response
    })
}

export const postFormResponse = async (url, payload) => {
    return axios(url, {
        baseURL: Server.ENV.imageURL,
        method: 'POST',
        headers: await getHeader(true),
        data: payload
    }).then(response => {
        if (response.data.success) {
            return response.data.data
        }
    }).catch(e => {
        if (e.response.status === 401) {
            window.location.href = '/'
        } else return e.response
    })
}

export const putResponse = async (url, payload) => {
    return axios(url, {
        method: 'PUT',
        headers: await getHeader(),
        // data: Encryption(JSON.stringify(payload))
        data: JSON.stringify(payload)
    }).then(response => {
        if (response.data.success) {
            // response.data.data = Decryption(response.data.data);
            if (response.data.data.datas) return response.data.data.datas;
            else return response.data.data;
        }
    }).catch(e => {
        if (e.response.status === 401) {
            window.location.href = '/'
        } else return e.response
    })
}

export const deleteResponse = async (url) => {
    return axios(url, {
        method: 'DELETE',
        headers: await getHeader()
    }).then(response => {
        if (response.data.success) {
            // response.data.data = Decryption(response.data.data);
            return response.data.data
        }
    }).catch(e => {
        if (e.response.status === 401) {
            window.location.href = '/'
        } else return e.response
    })
}