
import { useState, useRef, useEffect } from "react";
import { Input } from 'antd';
import { Services } from '../../__services';
import { ModalComponent, SpinnerComponent, TableComponent } from "../../Component/Common";


export const PatientSearch = ({ onClick }) => {
    const [spinner, setSpinner] = useState(true);
    const [allPatient, setAllPatient] = useState([]);
    const [filteredPatient, setFilteredPatient] = useState([]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getPatients();
            isFirstRender.current = false
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeHandler = (type, value) => {
        if (type === 'search') {
            setSpinner(true);
            setTimeout(() => { filterPatient(value) }, 1000);
        }
    }

    const getPatients = () => {
        try {
            let params = ``;
            params += `&customQuery=person,images,visits(location)`;
            Services.GetPatientWithParams(params).then(response => {
                if (response.rows?.length) {
                    let allSearchablePatient = response.rows.map(pt => {
                        return {
                            key: pt?.visits[0]?.id,
                            patient_id: pt?.id,
                            patient_identifier: pt?.patient_identifier,
                            abha_no: pt?.abha_no,
                            name: `${pt.person.first_name} ${pt.person.middle_name || ''} ${pt.person.last_name}`,
                            gender: pt?.person?.gender,
                            dob: pt?.person?.birthdate,
                            created_at: pt?.visits[0]?.created_at,
                            location: pt?.visits[0]?.location?.name,
                        }
                    }
                    );
                    setSpinner(false);
                    setAllPatient(allSearchablePatient);
                }

            })
        } catch (e) {
            console.log('Get Patient Exception: ', e);
        }
    }

    const filterPatient = (value = '') => {
        let lowercasedFilter = value !== undefined ? value.toString().toLowerCase() : '';
        let filteredData = allPatient.filter(item => item?.name?.toString().toLowerCase().includes(lowercasedFilter) || item.patient_identifier.toString().toLowerCase().includes(lowercasedFilter));
        setFilteredPatient(filteredData);
        setSpinner(false);
    }

    return (
        <>
            <ModalComponent
                width='900px'
                open={true}
                title='Patient Search'
                onCancel={() => onClick('showPatientSearch', false)}
                saveData={() => onClick('showPatientSearch', false)}>
                <Input placeholder="Search" size='large' style={{ width: 300 }} onChange={(e) => onChangeHandler('search', e.target.value)} />
                {spinner ?
                    <SpinnerComponent /> :
                    <>
                        <br />
                        <br/>
                        <TableComponent search={true} data={filteredPatient} />
                           
                    </>
                }
            </ModalComponent>
        </>
    )
}