import { Routes, Route } from 'react-router-dom';
import { Login } from '../Login';
import { HomePage } from '../Homepage';
import { VisitSummary } from '../VisitSummary';
import { ProtectedLayout } from '../ProtectedLayout';
import { Admin } from '../Admin';
import { Concept } from '../Admin/ClinicalProtocol/Concept';
import { User } from '../Admin/User';
import { ClinicalProtocol } from '../Admin/ClinicalProtocol';
import { LicenceKey } from '../Admin/ClinicalProtocol/Protocol';
import { VisualAcuity } from '../Admin/ClinicalProtocol/VisualAcuity';
import { Dashboard } from '../Admin/DataDashboard';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route element={<ProtectedLayout />}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/visitsummary/:id" element={<VisitSummary />} />
        <Route path="/admin" element={<Admin />} >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="user" element={<User/>} />
          <Route path="clinical" element={<ClinicalProtocol/>} >
            <Route path="protocol" element={<LicenceKey/>} />
            <Route path="acuity" element={<VisualAcuity/>} />
            <Route path="concept" element={<Concept/>} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );

}

export default App;