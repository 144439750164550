import { useState, useEffect, useRef } from "react";
import { Card, Input, Divider, Checkbox, notification, Alert, Space, Radio, Button, DatePicker } from "antd";
import { DeleteFilled } from '@ant-design/icons';
import { InputWrapper, InfectionTypeWrapper, IndiltrateWrapper, SpecificExamWrapper } from './style';
// import { SVGLibrary } from "../../../Component/Common";
// import Icon from '@ant-design/icons';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";
import dayjs from 'dayjs';

const CheckboxGroup = Checkbox.Group;

const firstList = [
    { svg: 'contactLens', value: 'Contact lens wear' },
    { svg: 'poorContactLensHygiene', value: 'Poor contact lens hygiene' },
    { svg: 'waterExposure', value: 'Water exposure (pool, natural)' },
    { svg: '', value: 'Topical steroid use' },
    { svg: '', value: 'Recent ocular surgery' },
    { svg: '', value: 'Chronic dacryocystitis' }
]

const secondList = [
    { svg: '', value: 'Trichiasis' },
    { svg: '', value: 'Prior keratoplasty in ulcer eye' },
    { svg: '', value: 'Prior herpetic eye disease' },
    { svg: '', value: 'Prior corneal ulcer in ulcer eye' },
    { svg: '', value: 'Diabetes' },
    { svg: '', value: 'Systemic immunosuppression' }
]

const thirdList = [
    { svg: '', value: 'Native medication use' }, { svg: '', value: 'Vegetative matter trauma' },
    { svg: '', value: 'Animal trauma' }, { svg: '', value: 'Recent ocular trauma' },
]

const otherRiskList = [
    { svg: 'other', value: 'Other risk factor' }
]

const infectionTypeList = [
    { value: 'Bacterial Keratitis' }, { value: 'Fungal Keratitis' },
    { value: 'Viral Keratitis' }, { value: 'Acanthamoeba' }, { value: 'Microsporidia' },
    { value: 'Pythium' }
]

const viralKerantitisList = [
    { value: 'Epithelial keratitis' }, { value: 'Stromal keratitis with epithelial defect' },
    { value: 'Stromal without epithelial defect' }, { value: 'Discifirm keratitis' },
    { value: 'Endotheliitis' }
]

const defectDiameterList = [
    { value: 'No epithelial defect' }, { value: '>0 to <2mm' }, { value: '2 to <6mm' }, { value: '> 6mm' }
]

const infiltrateList = {
    diameter: [{ value: '0 to <2 mm' }, { value: '2 to <6 mm' }, { value: '> 6 mm' }, { value: 'Not applicable' }],
    depth: [{ value: 'Anterior 1/3 stroma' }, { value: 'Middle 1/3 stroma' }, { value: 'Posterior 1/3 stroma' }, { value: 'Endothelial lesion' }, { value: 'Not applicable' }],
    centrality: [{ value: 'Central (0-4mm)' }, { value: 'Mid-peripheral (4-8mm)' }, { value: 'Peripheral (8-10mm)' }, { value: 'Diffuse (entire cornea)' }, { value: 'Not applicable' }],
    limbus: [{ value: 'No' }, { value: 'Yes' }]
}

const specificExamList1 = [
    { value: 'Superficial plaque' }, { value: 'Satellite lesions' }, { value: 'Feathery infiltrate margins' }, { value: 'Ring infiltrate' },
    { value: 'Endothelial plaque' }, { value: 'Keratic precipitates' },
]

const specificExamList2 = [
    { value: 'Dry ulcer surface' }, { value: 'Pigmented lesion' }, { value: 'Scleritis' }, { value: 'Impending perforation (Descemetocoele)' },
    { value: 'Frank perforation' }, { value: 'Endophthalmitis' }
]

const procedureList = [
    { value: 'Superficial debridement' }, { value: 'Gluing' }, { value: 'Intrastromal Injection' },
    { value: 'Intracameral injection' }, { value: 'Therapeutic Keratoplasty Decided' }, { value: 'Other intervention' },
]

const therapeuticList = [
    { value: 'Impending perforation' }, { value: 'Frank perforation' },
    { value: 'Limbal involvement' }, { value: 'Worsening infiltrate despite therapy' },
    { value: 'Other' }
]

export const InfectionHistory = ({ data, onChange, showFirstVisitInfection, name, submit }) => {
    const [api, contextHolder] = notification.useNotification();

    const [infectionSymptomDays, setInfectionSymptomDays] = useState([]);
    const [infectionPresentationDays, setInfectionPresentationDays] = useState([]);
    const [infectionRiskFactor, setInfectionRiskFactor] = useState([]);
    const [infectionInfectionType, setInfectionInfectionType] = useState([]);
    const [infectionEpithelialDiameter, setInfectionEpithelialDiameter] = useState([]);
    const [infectionDiameter, setInfectionDiameter] = useState([]);
    const [infectionCentrality, setInfectionCentrality] = useState([]);
    const [infectionDepth, setInfectionDepth] = useState([]);
    const [infectionLimbus, setInfectionLimbus] = useState([]);
    const [infectionStromal, setInfectionStromal] = useState([]);
    const [infectionHypopyon, setInfectionHypopyon] = useState([]);
    const [infectionSpecificExam, setInfectionSpecificExam] = useState([]);
    const [infectionProcedure, setInfectionProcedure] = useState([]);
    const [infectionTherapeutic, setInfectionTherapeutic] = useState([]);
    // const [followUpDiagnosis, setFollowUpDiagnosis] = useState([]);
    const [microbiologyDiagnosis, setMicrobiologyDiagnosis] = useState([]);

    const [symptonDays, setSymptonDays] = useState();
    const [presentationDays, setPresentationDays] = useState();
    const [infections, setInfections] = useState([]);
    const [otherRisk, setOtherRisk] = useState();
    const [ocularTrauma, setOcularTrauma] = useState();
    const [animalTrauma, setAnimalTrauma] = useState();

    const [infectionType, setInfectionType] = useState([]);
    const [subInfectionType, setSubInfectionType] = useState([]);
    const [epithelialDiameter, setEpithelialDiameter] = useState();
    const [infiltrate, setInfiltrate] = useState({ diameter: [], centrality: [], depth: [], limbus: '' });
    const [hypopyon, setHypopyon] = useState();
    const [stromal, setStromal] = useState();
    const [specificExam, setSpecificExam] = useState([]);
    const [procedure, setProcedure] = useState([]);
    const [therapeutic, setTherapeutic] = useState([]);
    // const [followUp, setFollowUp] = useState('');
    // const [followUpValue, setFollowUpValue] = useState('');
    const [microbiology, setMicrobiology] = useState('');

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getInfectionHistory();
            isFirstRender.current = false
            return;
        }

        if (microbiology.length || submit) {
            submitInfectionHistory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [microbiology, submit])

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteInfection') {
            deleteInfection(value, path);
        }
    }

    const onChangeHandler = (type, value, objectType) => {
        if (type === 'infection') {
            setInfections(value);
        } else if (type === 'infectionType') {
            setInfectionType(value);
        } else if (type === 'subInfectionType') {
            setSubInfectionType(value);
        } else if (type === 'epithelialDiameter') {
            setEpithelialDiameter(value);
        } else if (type === 'infiltrate') {
            setInfiltrate({
                ...infiltrate,
                [objectType]: value
            })
        } else if (type === 'stromal') {
            setStromal(value);
        } else if (type === 'hypopyon') {
            setHypopyon(value);
        } else if (type === 'specificExam') {
            let newData = [...specificExam];
            let index = newData.findIndex(dt => dt.name === objectType);
            if (index !== -1) {
                newData.splice(index, 1);
            }
            setSpecificExam([...newData, { name: objectType, value }]);
        } else if (type === 'procedure') {
            let newData = [...procedure];
            let index = newData.findIndex(dt => dt.name === objectType);
            if (index !== -1) {
                newData.splice(index, 1);
            }
            setProcedure([...newData, { name: objectType, value }]);
        } else if (type === 'therapeutic') {
            let newData = [...therapeutic];
            let index = newData.findIndex(dt => dt.name === objectType);
            if (index !== -1) {
                newData.splice(index, 1);
            }
            setTherapeutic([...newData, { name: objectType, value }]);
        } else if (type === 'microbiology') {
            setMicrobiology(value);
            if (value === 'Yes') {
                onChange('setCoordinatorVisit', true);
            } else {
                onChange('setCoordinatorVisit', false);
            }
        } 
        // else if (type === 'followUp') {
        //     setFollowUp(value);
        // }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getInfectionHistory = async () => {
        setInfectionSymptomDays(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSYMPTOMSDAYS' : 'INFECTIONHISTORYLEFTSYMPTOMSDAYS']) || []);
        setInfectionPresentationDays(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTPRESENTATIONDATE' : 'INFECTIONHISTORYLEFTPRESENTATIONDATE']) || []);
        setInfectionRiskFactor(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTRISKFACTOR' : 'INFECTIONHISTORYLEFTRISKFACTOR']) || []);

        setInfectionInfectionType(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTWORKING' : 'INFECTIONHISTORYLEFTWORKING']) || []);
        setInfectionEpithelialDiameter(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTEPITHELIAL' : 'INFECTIONHISTORYLEFTEPITHELIAL']) || []);
        setInfectionDiameter(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTDIAMETER' : 'INFECTIONHISTORYLEFTDIAMETER']) || []);
        setInfectionDepth(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTDEPTH' : 'INFECTIONHISTORYLEFTDEPTH']) || []);
        setInfectionCentrality(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTCENTERALLY' : 'INFECTIONHISTORYLEFTCENTERALLY']) || []);
        setInfectionLimbus(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTLIMBUS' : 'INFECTIONHISTORYLEFTLIMBUS']) || []);
        setInfectionStromal(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSTROMAL' : 'INFECTIONHISTORYLEFTSTROMAL']) || [])
        setInfectionHypopyon(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTHYPOPYON' : 'INFECTIONHISTORYLEFTHYPOPYON']) || []);
        setInfectionSpecificExam(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSPECIFIC' : 'INFECTIONHISTORYLEFTSPECIFIC']) || []);
        setInfectionProcedure(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTPROCEDURE' : 'INFECTIONHISTORYLEFTPROCEDURE']) || []);
        setInfectionTherapeutic(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTTHERAPEUTIC' : 'INFECTIONHISTORYLEFTTHERAPEUTIC']) || []);

        // setFollowUpDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTFOLLOWUP' : 'INFECTIONHISTORYLEFTFOLLOWUP']) || []);
        setMicrobiologyDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTMICROBIOLOGY' : 'INFECTIONHISTORYLEFTMICROBIOLOGY']) || []);

    }

    const submitInfectionHistory = async () => {
        let user = await getFromLocalStorage('user');
        let allData = []
        // if (((!symptonDays || !presentationDays || !infections.length) && showFirstVisitInfection) || !infectionType || !epithelialDiameter || !infiltrate.diameter.length ||
        //     !infiltrate.depth.length || !infiltrate.centrality.length || !infiltrate.limbus.length || !stromal || !hypopyon || !specificExam.length || !procedure.length) {
        //     openNotificationWithIcon('error', 'Fill Infection History');
        //     return;
        // } else {
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (symptonDays) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSYMPTOMSDAYS' : 'INFECTIONHISTORYLEFTSYMPTOMSDAYS'],
                obs_number: {
                    value: symptonDays
                }
            })
        }
        if (presentationDays) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTPRESENTATIONDATE' : 'INFECTIONHISTORYLEFTPRESENTATIONDATE'],
                obs_string: {
                    value: presentationDays
                }
            })
        }
        if (infections.length) {
            let ocular = infections.indexOf('Recent ocular trauma');
            if (ocular !== -1) {
                infections.splice(ocular, 1);
                infections.push(`Recent ocular trauma - ${ocularTrauma}`);
            }
            let animalTrauma = infections.indexOf('Recent ocular trauma');
            if (animalTrauma !== -1) {
                infections.splice(animalTrauma, 1);
                infections.push(`Animal trauma - ${ocularTrauma}`);
            }
            let other = infections.indexOf('Other risk factor');
            if (other !== -1) {
                infections.splice(other, 1);
                infections.push(otherRisk);
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTRISKFACTOR' : 'INFECTIONHISTORYLEFTRISKFACTOR'],
                obs_string: {
                    value: infections.join(', ')
                }
            })
        }
        if (infectionType.length) {
            let infection = [...infectionType], processedString = '';
            let viral = infection.indexOf('Viral Keratitis');
            if (viral !== -1) {
                processedString += `Viral Keratitis - ${subInfectionType.join(', ')}`
                infection.splice(viral, 1);
            }
            processedString += infection.join(', ');
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTWORKING' : 'INFECTIONHISTORYLEFTWORKING'],
                obs_string: {
                    value: processedString
                }
            })
        }
        if (epithelialDiameter) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTEPITHELIAL' : 'INFECTIONHISTORYLEFTEPITHELIAL'],
                obs_string: {
                    value: `Epithelial defect diameter - ${epithelialDiameter}`
                }
            })
        }
        if (infiltrate.diameter.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTDIAMETER' : 'INFECTIONHISTORYLEFTDIAMETER'],
                obs_string: {
                    value: infiltrate.diameter.join(', ')
                }
            })
        }
        if (infiltrate.depth.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTDEPTH' : 'INFECTIONHISTORYLEFTDEPTH'],
                obs_string: {
                    value: infiltrate.depth.join(', ')
                }
            })
        }
        if (infiltrate.centrality.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTCENTERALLY' : 'INFECTIONHISTORYLEFTCENTERALLY'],
                obs_string: {
                    value: infiltrate.centrality.join(', ')
                }
            })
        }
        if (infiltrate.limbus.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTLIMBUS' : 'INFECTIONHISTORYLEFTLIMBUS'],
                obs_string: {
                    value: infiltrate.limbus
                }
            })
        }
        if (stromal) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSTROMAL' : 'INFECTIONHISTORYLEFTSTROMAL'],
                obs_string: {
                    value: `Stromal - ${stromal}`
                }
            })
        }
        if (hypopyon) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTHYPOPYON' : 'INFECTIONHISTORYLEFTHYPOPYON'],
                obs_string: {
                    value: `Hypopyon Present - ${hypopyon}`
                }
            })
        }
        if (specificExam.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSPECIFIC' : 'INFECTIONHISTORYLEFTSPECIFIC'],
                obs_string: {
                    value: specificExam?.map(ex => `${ex.name} - ${ex.value}`).join(', ')
                }
            })
        }
        if (procedure.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTPROCEDURE' : 'INFECTIONHISTORYLEFTPROCEDURE'],
                obs_string: {
                    value: procedure?.map(ex => `${ex.name} - ${ex.value}`).join(', ')
                }
            })
        }
        if (therapeutic.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTTHERAPEUTIC' : 'INFECTIONHISTORYLEFTTHERAPEUTIC'],
                obs_string: {
                    value: therapeutic?.map(ex => `${ex.name} - ${ex.value}`).join(', ')
                }
            })
        }
        // if (followUp.length) {
        //     allData.push({
        //         ...payload,
        //         concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTFOLLOWUP' : 'INFECTIONHISTORYLEFTFOLLOWUP'],
        //         obs_string: {
        //             value: followUp !== 'Yes' ? followUp : followUpValue
        //         }
        //     })
        // }
        if (microbiology.length) {
            allData.push({
                ...payload,
                concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTMICROBIOLOGY' : 'INFECTIONHISTORYLEFTMICROBIOLOGY'],
                obs_string: {
                    value: microbiology
                }
            })
        }
        Services.PostObs(allData).then(response => {
            if (response.status !== 400) {
                let infSymD = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSYMPTOMSDAYS' : 'INFECTIONHISTORYLEFTSYMPTOMSDAYS']);
                let presD = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTPRESENTATIONDATE' : 'INFECTIONHISTORYLEFTPRESENTATIONDATE']);
                let riskF = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTRISKFACTOR' : 'INFECTIONHISTORYLEFTRISKFACTOR']);
                let working = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTWORKING' : 'INFECTIONHISTORYLEFTWORKING']);
                let epithe = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTEPITHELIAL' : 'INFECTIONHISTORYLEFTEPITHELIAL']);
                let diam = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTDIAMETER' : 'INFECTIONHISTORYLEFTDIAMETER']);
                let dep = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTDEPTH' : 'INFECTIONHISTORYLEFTDEPTH']);
                let cent = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTCENTERALLY' : 'INFECTIONHISTORYLEFTCENTERALLY']);
                let lim = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTLIMBUS' : 'INFECTIONHISTORYLEFTLIMBUS']);
                let stro = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSTROMAL' : 'INFECTIONHISTORYLEFTSTROMAL']);
                let hypop = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTHYPOPYON' : 'INFECTIONHISTORYLEFTHYPOPYON']);
                let spec = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTSPECIFIC' : 'INFECTIONHISTORYLEFTSPECIFIC']);
                let proc = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTPROCEDURE' : 'INFECTIONHISTORYLEFTPROCEDURE']);
                let thera = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTTHERAPEUTIC' : 'INFECTIONHISTORYLEFTTHERAPEUTIC']);
                // let follw = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTFOLLOWUP' : 'INFECTIONHISTORYLEFTFOLLOWUP']);
                let micro = response.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTMICROBIOLOGY' : 'INFECTIONHISTORYLEFTMICROBIOLOGY']);
                if (infSymD.length) {
                    setInfectionSymptomDays([...infectionSymptomDays, ...infSymD]);
                }
                if (presD.length) {
                    setInfectionPresentationDays([...infectionPresentationDays, ...presD]);
                }
                if (riskF.length) {
                    setInfectionRiskFactor([...infectionRiskFactor, ...riskF]);
                }
                if (working.length) {
                    setInfectionInfectionType([...infectionInfectionType, ...working]);
                }
                if (epithe.length) {
                    setInfectionEpithelialDiameter([...infectionEpithelialDiameter, ...epithe]);
                }
                if (diam.length) {
                    setInfectionDiameter([...infectionDiameter, ...diam]);
                }
                if (dep.length) {
                    setInfectionDepth([...infectionDepth, ...dep]);
                }
                if (cent.length) {
                    setInfectionCentrality([...infectionCentrality, ...cent]);
                }
                if (lim.length) {
                    setInfectionLimbus([...infectionLimbus, ...lim]);
                }
                if (stro.length) {
                    setInfectionStromal([...infectionStromal, ...stro]);
                }
                if (hypop.length) {
                    setInfectionHypopyon([...infectionHypopyon, ...hypop]);
                }
                if (spec.length) {
                    setInfectionSpecificExam([...infectionSpecificExam, ...spec]);
                }
                if (proc.length) {
                    setInfectionProcedure([...infectionProcedure, ...proc]);
                }
                if (thera.length) {
                    setInfectionTherapeutic([...infectionTherapeutic, ...thera]);
                }
                // if (follw.length) {
                //     setFollowUpDiagnosis([...followUpDiagnosis, ...follw]);
                // }
                if (micro.length) {
                    setMicrobiologyDiagnosis([...microbiologyDiagnosis, ...micro]);
                }
                resetField();
                openNotificationWithIcon('success', 'Infection History Submitted');
            }
        })
        // }
    }

    const deleteInfection = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'infectionSymptomDays') {
                    let newData = [...infectionSymptomDays];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionSymptomDays(newData);
                    }
                } else if (path === 'infectionPresentationDays') {
                    let newData = [...infectionPresentationDays];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionPresentationDays(newData);
                    }
                } else if (path === 'infectionRiskFactor') {
                    let newData = [...infectionRiskFactor];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionRiskFactor(newData);
                    }
                } else if (path === 'infectionInfectionType') {
                    let newData = [...infectionInfectionType];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionInfectionType(newData);
                    }
                } else if (path === 'infectionEpithelialDiameter') {
                    let newData = [...infectionEpithelialDiameter];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionEpithelialDiameter(newData);
                    }
                } else if (path === 'infectionDiameter') {
                    let newData = [...infectionDiameter];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionDiameter(newData);
                    }
                } else if (path === 'infectionDepth') {
                    let newData = [...infectionDepth];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionDepth(newData);
                    }
                } else if (path === 'infectionCentrality') {
                    let newData = [...infectionCentrality];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionCentrality(newData);
                    }
                } else if (path === 'infectionLimbus') {
                    let newData = [...infectionLimbus];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionLimbus(newData);
                    }
                } else if (path === 'infectionStromal') {
                    let newData = [...infectionStromal];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionStromal(newData);
                    }
                } else if (path === 'infectionHypopyon') {
                    let newData = [...infectionHypopyon];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionHypopyon(newData);
                    }
                } else if (path === 'infectionSpecificExam') {
                    let newData = [...infectionSpecificExam];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionSpecificExam(newData);
                    }
                } else if (path === 'infectionProcedure') {
                    let newData = [...infectionProcedure];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionProcedure(newData);
                    }
                } else if (path === 'infectionTherapeutic') {
                    let newData = [...infectionTherapeutic];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setInfectionTherapeutic(newData);
                    }
                } 
                // else if (path === 'followUpDiagnosis') {
                //     let newData = [...followUpDiagnosis];
                //     let index = newData.findIndex(dt => dt.id === id);
                //     if (index !== -1) {
                //         newData.splice(index, 1);
                //         setFollowUpDiagnosis(newData);
                //     }
                // } 
                else if (path === 'microbiologyDiagnosis') {
                    let newData = [...microbiologyDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setMicrobiologyDiagnosis(newData);
                    }
                }
                openNotificationWithIcon('success', 'Infection History Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setSymptonDays('');
        setPresentationDays('');
        setInfections([]);
        setOtherRisk('');
        setOcularTrauma('');
        setAnimalTrauma('');

        setInfectionType([]);
        setSubInfectionType([]);
        setEpithelialDiameter('');
        setInfiltrate({ diameter: [], centrality: [], depth: [], limbus: '' })
        setStromal('');
        setHypopyon('');
        setSpecificExam([]);
        setProcedure([]);
        setTherapeutic([])
        // setFollowUp('');
        // setFollowUpValue('');
        setMicrobiology('');
    }

    return (
        <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <Card title={(<>Infection History {name}</>)}>
                {showFirstVisitInfection ? <>
                    <InputWrapper>
                        <div>
                            Days since symptoms began <span>(required)</span> :
                            <Input size="medium" type="number" min="1" placeholder="days" value={symptonDays} onChange={e => setSymptonDays(e.target.value)} />
                        </div>
                        <div>
                            Presentation to this hospital  &nbsp;
                            <Input size="medium" style={{ width: 150 }} type="number" min="0" placeholder="0 (today) days" value={presentationDays} onChange={e => setPresentationDays(e.target.value)} />
                            &nbsp;<span>days ago</span>
                            {/* <DatePicker size="medium" style={{ width: 350, display: 'block' }} format="YYYY-MM-DD" onChange={(e, format) => setPresentationDays(format)} /> */}
                        </div>
                    </InputWrapper>
                    <br />
                    <div><b>Infection risk factors</b> (required, enter all that apply): </div>
                    <Divider />

                    <CheckboxGroup value={infections} style={{ width: '100%' }} onChange={(e) => onChangeHandler('infection', e)}>
                        <div style={{ display: 'flex', gap: '10%', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Checkbox style={{ marginInlineStart: 0 }} value='No risk factor'>No risk factor</Checkbox>
                                {firstList?.map((infection, index) =>
                                    <Checkbox key={index} style={{ marginInlineStart: 0 }} value={infection.value}>{infection.value}</Checkbox>
                                )}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {secondList?.map((infection, index) =>
                                    <Checkbox key={index} style={{ marginInlineStart: 0 }} value={infection.value}>{infection.value}</Checkbox>
                                )}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {thirdList?.map((infection, index) =>
                                        <div style={{ display: "flex" }}>
                                            <Checkbox key={index} style={{ marginInlineStart: 0 }} value={infection.value}>{infection.value}</Checkbox>
                                            {infection.value === 'Animal trauma' && infections.includes('Animal trauma') ?
                                                <Input placeholder="Enter text" value={animalTrauma} onChange={e => setAnimalTrauma(e.target.value)} style={{ width: 150, margin: 5 }} />
                                                : null}
                                            {infection.value === 'Recent ocular trauma' && infections.includes('Recent ocular trauma') ?
                                                <Input placeholder="Enter text" value={ocularTrauma} onChange={e => setOcularTrauma(e.target.value)} style={{ width: 150, margin: 5 }} />
                                                : null}
                                        </div>
                                    )}
                                </div>

                                <br />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {otherRiskList?.map(infection =>
                                        <Checkbox style={{ marginInlineStart: 0 }} value={infection.value}>{infection.value}</Checkbox>
                                    )}
                                    {infections.includes('Other risk factor') ?
                                        <>
                                            {/* <br /> */}
                                            <Input placeholder="Enter text" value={otherRisk} onChange={e => setOtherRisk(e.target.value)} style={{ width: 150 }} />
                                        </> :
                                        null}
                                </div>
                            </div>
                        </div>
                    </CheckboxGroup>
                </> : null}




                {/* New */}
                <>
                    <h4>Infection Type</h4>
                    <Divider />
                    <InfectionTypeWrapper>
                        <CheckboxGroup value={infectionType} onChange={(e) => onChangeHandler('infectionType', e)}>
                            <Space direction="vertical">
                                {infectionTypeList?.map((infection, index) => <Checkbox key={index} value={infection.value} >{infection.value}</Checkbox>)}
                            </Space>
                        </CheckboxGroup>
                        {infectionType.includes('Viral Keratitis') ?
                            <CheckboxGroup value={subInfectionType} onChange={(e) => onChangeHandler('subInfectionType', e)}>
                                <Space direction="vertical">
                                    {viralKerantitisList?.map((viral, index) => <Checkbox key={index} value={viral.value}>{viral.value}</Checkbox>)}
                                </Space>
                            </CheckboxGroup>
                            : null

                        }
                    </InfectionTypeWrapper>
                </>
                <br />
                <br />
                <>
                    <h4>Epithelial defect diameter</h4>
                    <Divider />
                    <CheckboxGroup value={epithelialDiameter} onChange={(e) => onChangeHandler('epithelialDiameter', e)}>
                        <Space direction="vertical">
                            {defectDiameterList?.map((dia, index) => <Checkbox key={index} value={dia.value}>{dia.value}</Checkbox>)}
                        </Space>
                    </CheckboxGroup>
                </>
                <br />
                <br />
                <>
                    <h4>Infiltrate/Scar Measurements</h4>
                    <Divider />
                    <IndiltrateWrapper>
                        <div>
                            <h5>DIAMETER:</h5>
                            <CheckboxGroup value={infiltrate.diameter} onChange={(e) => onChangeHandler('infiltrate', e, 'diameter')}>
                                <Space direction="vertical">
                                    {infiltrateList.diameter?.map((lt, index) => <Checkbox key={index} value={lt.value}>{lt.value}</Checkbox>)}
                                </Space>
                            </CheckboxGroup>
                        </div>
                        <div>
                            <h5>DEPTH:</h5>
                            <CheckboxGroup value={infiltrate.depth} onChange={(e) => onChangeHandler('infiltrate', e, 'depth')}>
                                <Space direction="vertical">
                                    {infiltrateList.depth?.map((lt, index) => <Checkbox key={index} value={lt.value}>{lt.value}</Checkbox>)}
                                </Space>
                            </CheckboxGroup>
                        </div>
                        <div>
                            <h5>CENTRALITY:</h5>
                            <CheckboxGroup value={infiltrate.centrality} onChange={(e) => onChangeHandler('infiltrate', e, 'centrality')}>
                                <Space direction="vertical">
                                    {infiltrateList.centrality?.map((lt, index) => <Checkbox key={index} value={lt.value}>{lt.value}</Checkbox>)}
                                </Space>
                            </CheckboxGroup>
                        </div>
                        <div>
                            <h5>Infiltrate reaching within 2mm of limbus?</h5>
                            <Radio.Group checked={infiltrate.limbus} onChange={(e) => onChangeHandler('infiltrate', e.target.value, 'limbus')}>
                                <Space direction="vertical">
                                    {infiltrateList.limbus?.map((lt, index) => <Radio key={index} value={lt.value}>{lt.value}</Radio>)}
                                </Space>
                            </Radio.Group>
                        </div>
                    </IndiltrateWrapper>
                </>
                <br />
                <br />
                <>
                    <h4>Stromal Thinning?</h4>
                    <Divider />
                    <Radio.Group value={stromal} onChange={(e) => onChangeHandler('stromal', e.target.value)}>
                        <Space direction="horizontal">
                            {['No', 'Yes']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                        </Space>
                    </Radio.Group>
                    {stromal && stromal !== 'No' ? <><Input placeholder="Percent stromal thickness remaining" onChange={e => setStromal(`${e.target.value} %`)} style={{ width: 300 }} /> %</> : null}
                </>
                <br />
                <br />
                <>
                    <h4>Hypopyon Present?</h4>
                    <Divider />
                    <Radio.Group value={hypopyon} onChange={(e) => onChangeHandler('hypopyon', e.target.value)}>
                        <Space direction="horizontal">
                            {['No', 'Yes']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                        </Space>
                    </Radio.Group>
                    {hypopyon && hypopyon !== 'No' ? <><Input placeholder="Hypopyon height" onChange={e => setHypopyon(`${e.target.value} mm`)} style={{ width: 300 }} /> mm</> : null}
                </>
                <br />
                <br />
                <>
                    <h4>Specific Exam Findings</h4>
                    <Divider />
                    <SpecificExamWrapper>
                        <table>
                            {specificExamList1?.map((exam, index) =>
                                <tr key={index}>
                                    <td>{exam.value}</td>
                                    <td><Radio.Group onChange={(e) => onChangeHandler('specificExam', e.target.value, exam.value)}>
                                        <Space direction="horizontal">
                                            {['No', 'Yes']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                                        </Space>
                                    </Radio.Group></td>
                                </tr>
                            )}
                        </table>
                        <table>
                            {specificExamList2?.map((exam, index) =>
                                <tr key={index}>
                                    <td>{exam.value}</td>
                                    <td><Radio.Group onChange={(e) => onChangeHandler('specificExam', e.target.value, exam.value)}>
                                        <Space direction="horizontal">
                                            {['No', 'Yes']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                                        </Space>
                                    </Radio.Group></td>
                                </tr>
                            )}
                        </table>
                    </SpecificExamWrapper>
                </>
                <br />
                <br />
                <>
                    <h4>Major Events at Today’s Visit</h4>
                    <Divider />
                    <table>
                        {procedureList?.map((pro, index) =>
                            <tr key={index}>
                                <td>{pro.value}</td>
                                <td><Radio.Group onChange={(e) => onChangeHandler('procedure', e.target.value, pro.value)}>
                                    <Space direction="horizontal">
                                        {['No', 'Yes']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                                    </Space>
                                </Radio.Group></td>
                                {['Intrastromal Injection', 'Intracameral injection'].includes(pro.value) && procedure.some(pr => pr.name === pro.value && pr.value !== 'No') ? <td><Input placeholder="Drug" onChange={(e) => onChangeHandler('procedure', e.target.value, pro.value)} /></td> : null}
                                {['Other intervention'].includes(pro.value) && procedure.some(pr => pr.name === pro.value && pr.value !== 'No') ? <td><Input placeholder="Details" onChange={(e) => onChangeHandler('procedure', e.target.value, pro.value)} /></td> : null}
                                {/* {['Intrastromal Injection', 'Therapeutic Keratoplasty Decided'].includes(pro.value) && procedure.some(pr => pr.name === pro.value && pr.value !== 'No') ? <td><Input placeholder="Drug" onChange={(e) => onChangeHandler('procedure', e.target.value, pro.value)} /></td> : null} */}
                            </tr>
                        )}
                    </table>
                </>
                <br />
                <br />
                {procedure.some(pr => pr.name === 'Therapeutic Keratoplasty Decided' && pr.value === 'Yes') ? <>
                    <h4>Reason for Therapeutic Keratoplasty</h4>
                    <Divider />
                    <table>
                        {therapeuticList?.map((ther, index) =>
                            <tr key={index}>
                                <td>{ther.value}</td>
                                <td><Radio.Group onChange={(e) => onChangeHandler('therapeutic', e.target.value, ther.value)}>
                                    <Space direction="horizontal">
                                        {['No', 'Yes']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                                    </Space>
                                </Radio.Group></td>
                                {['Other'].includes(ther.value) && therapeutic.some(pr => pr.name === ther.value && pr.value !== 'No') ? <td><Input placeholder="Specify" onChange={(e) => onChangeHandler('therapeutic', e.target.value, ther.value)} /></td> : null}
                            </tr>
                        )}
                    </table>
                </> : null}
                {infectionSymptomDays?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_number.value} days since symptom began</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionSymptomDays')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionPresentationDays?.map(presDay => <div key={presDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{presDay?.obs_string?.value} - presentation to eye hospital</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', presDay.id, 'infectionPresentationDays')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionRiskFactor?.map(riskFactor => <div key={riskFactor.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{riskFactor.obs_string.value}</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', riskFactor.id, 'infectionRiskFactor')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionInfectionType?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionInfectionType')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionEpithelialDiameter?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionEpithelialDiameter')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionDiameter?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionDiameter')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionDepth?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionDepth')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionCentrality?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionCentrality')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionLimbus?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionLimbus')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionStromal?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionStromal')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionHypopyon?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionHypopyon')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionSpecificExam?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionSpecificExam')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionProcedure?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionProcedure')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                {infectionTherapeutic?.map(sypDay => <div key={sypDay.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{sypDay.obs_string.value} </div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', sypDay.id, 'infectionTherapeutic')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                <br />
                {/* <Divider />
                <div>
                    <>
                        <div>Is follow up required?</div>
                        <Radio.Group onChange={(e) => onChangeHandler('followUp', e.target.value)}>
                            <Space direction="horizontal">
                                {['Yes', 'No', 'As needed']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                            </Space>
                        </Radio.Group>
                    </>
                    {followUp.match('Yes') ?
                        <div>
                            <br />
                            <b>Next scheduled follow-up visit</b>
                            <DatePicker size="medium" value={followUpValue !== "" ? dayjs(followUpValue) : null} style={{ width: 350, display: 'block' }} format="YYYY-MM-DD" onChange={(e, format) => setFollowUpValue(format)} />
                        </div>
                        : null}
                </div> */}
                {/* {followUpDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{diagnosis.obs_string.value}</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteInfection', diagnosis.id, 'followUpDiagnosis')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                <br /> */}
                <Divider />
                <div>
                    <b>Microbiology Testing</b>
                    <br />
                    <br />
                    <div>After today’s imaging, should patient get scraping/smear?</div>
                    <Radio.Group onChange={(e) => onChangeHandler('microbiology', e.target.value)}>
                        <Space direction="horizontal">
                            {['No', 'Yes']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                        </Space>
                    </Radio.Group>
                    {microbiologyDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                        (
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>{diagnosis.obs_string.value}</div>
                                <DeleteFilled onClick={() => onClickHandler('deleteInfection', diagnosis.id, 'microbiologyDiagnosis')} style={{ color: 'red' }} />
                            </div>)
                    } /></div>
                    )}
                </div>
                <br />
                {/* <div style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={submitInfectionHistory}>Submit Infection History</Button>
                </div> */}
            </Card>
            {contextHolder}
        </div>
    )
}