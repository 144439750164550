const BUILD_ENV = "sceh";

const allENV = {
  local: {
    baseURL: "http://localhost:3004/api/v2",
    imageURL: "https://visilant-pndy-upload-image.azurewebsites.net/api",
    name: 'local'
  },
  visilant: {
    baseURL: "https://devapi.visilant.org/api/v2",
    imageURL: '',
    name: 'visilant'
  },
  pndy: {
    baseURL: "https://pndyapi.visilant.org/api/v2",
    imageURL: "https://visilant-pndy-upload-image.azurewebsites.net/api",
    name: 'pndy'
  },
  img: {
    baseURL: "https://imgapi.visilant.org/api/v2",
    imageURL: '',
    name: 'img'
  },
  snc: {
    baseURL: "https://sncapi.visilant.org/api/v2",
    imageURL: 'https://visilant-pndy-upload-image.azurewebsites.net/api',
    name: 'snc',
  },
  sceh: {
    baseURL: "https://scehapi.visilant.org/api/v2",
    imageURL: 'https://visilant-tvl-upload-image.azurewebsites.net/api',
    name: 'sceh'
  }
};

const ENV = allENV[BUILD_ENV];

export const Server = {
  ENV,
};
